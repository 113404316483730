import React from 'react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlideCard from './SlideCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



const Slider = () => {
    const vehicles = [
        {
            name: "Rogue",
            car_model_id: 3560,
            car_model_year: 2025,

            description: "APR desde 2.99%",
            legal: "Oferta desde 2.99% APR aplica a todas las variantes de Nissan Rogue 2024 y 2025 para clientes con puntuación de crédito de 795 en adelante con financiación de 60 meses, producto ofrecido por Popular Auto LLC. Otras tasas aplican a otras experiencias de crédito, según los parámetros y criterios establecidos por Popular Auto LLC. Popular Auto LLC y la red de dealers Nissan no son afiliados. Sujeto a aprobación de crédito. Otras tasas aplican a otras experiencias de crédito, según los parámetros y criterios establecidos por Oriental Bank. Oriental Bank y la red de dealers Nissan no son afiliados. Ciertos términos, restricciones y condiciones aplican. Ofertas publicadas no pueden combinarse con otras ofertas, descuentos o entre sí. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por versión visite nissan.pr o en los concesionarios Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. Oferta válida del 1 al 28 de febrero de 2025.",
            cta1url: "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
            cta1label: "Coordina una prueba de manejo",
            cta2url: "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
            cta2label: "Solicita un estimado",
            image_desk: "/sliders/slider-Rogue-desk-25-v2.png",
            image_mob: "/sliders/slider-Rogue-mob-25-v2.png",
        },
        {
            name: "Versa",
            car_model_id: 26,
            car_model_year: 2024,
            // <br class='md:hidden'/> 
            description: "Desde $19,995<sup class='top:9px'>(1)</sup>&nbsp;o APR desde 2.99%<sup class='top:9px'>(2)</sup>",
            legal: "Cliente escoge entre: (1) $19,995 luego de aplicado el bono de $2,160 ($1,910 del distribuidor más $250 del concesionario) aplicable al pronto pago de la variante VES-2403 solamente. Ejemplo VES-2403, MSRP de $22,155 menos $2,160 de bono, balance a financiar de $19,995. Para las variantes VES-2406, VES-2405 aplica bono de $1,000 ($750 del distribuidor más $250 del concesionario (2) Versa 2024 Modelo VES-2403 MSRP $22,155; $0 de pronto, balance a financiar $22,155 a 72 pagos mensuales de $336.57, al 2.99% APR a través de Popular Auto LLC para clientes con puntuación de crédito 750+ al acogerse a pago directo. Producto ofrecido por Popular Auto LLC. Otras tasas aplican a otras experiencias de crédito, según los parámetros y criterios establecidos por Popular Auto LLC. Popular Auto LLC y la red de dealers Nissan no son afiliados. Sujeto a aprobación de crédito. Ciertos términos, restricciones y condiciones aplican. Ofertas publicadas no pueden combinarse con otras ofertas, descuentos o entre sí. No incluye seguros, tablilla, ACAA, gastos de registro ni accesorios. Ofertas aplican al acogerse a pago directo. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por variante, disponibilidad de tecnologías, visite nissan.pr o los concesionarios Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. El rendimiento de combustible puede variar dependiendo de sus hábitos de manejo, condiciones de la carretera y otros factores. Oferta válida del el 1 al 28 de febrero de 2025.",
            cta1url: "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
            cta1label: "Coordina una prueba de manejo",
            cta2url: "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
            cta2label: "Solicita un estimado",
            image_desk: "/sliders/Slider-Versa-desktop.jpg",
            image_mob: "/sliders/Slider-Versa-mobile.jpg",
        },
    ];

    return (
        <Swiper
            id='slider'
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
        >

            {vehicles.map((vehicle, index) => (
                <SwiperSlide key={index}>
                    <SlideCard
                        carmodelid={vehicle.car_model_id}
                        carname={vehicle.name}
                        caryear={vehicle.car_model_year}
                        legal={vehicle.legal}
                        buttontext={vehicle.description}
                        ctaurl={vehicle.cta2url}
                        ctalabel={vehicle.cta2label}
                        image_desk={vehicle.image_desk}
                        image_mob={vehicle.image_mob}
                    />
                </SwiperSlide>
            ))}

        </Swiper >
    );
};

export default Slider;
